import { createSlice } from '@reduxjs/toolkit';

export interface UploadingState {
  accountUploaded: string[],
  alreadyExisted: string[],
  uploadLoading: boolean,
  businessName: any,
  businessEmail: any
}

const initialState: UploadingState = {
  accountUploaded: [],
  alreadyExisted: [],
  uploadLoading: false,
  businessName: '',
  businessEmail: ''
}

export const accountUploadSlice = createSlice({
  name: 'account_uploading',
  initialState,
  reducers: {
    mountAccounts: (state, actions) => {
      state.accountUploaded.push(actions.payload)
    },

    mountExistingAccounts: (state, actions) => {
      state.alreadyExisted.push(actions.payload)
    },

    uploadIsLoading: (state, actions) => {
      state.uploadLoading = actions.payload
    },

    clearMountedAccounts: (state) => {
      state.accountUploaded = []
    },

    clearAlreadyExisting: (state) => {
      state.alreadyExisted = []
    },
    setBusinessName: (state, actions) => {
      state.businessName = actions.payload
    },
    setBusinessEmail: (state, actions) => {
      state.businessEmail = actions.payload
    }
  }
})

export const { mountAccounts, mountExistingAccounts, uploadIsLoading, clearMountedAccounts, clearAlreadyExisting, setBusinessName, setBusinessEmail } = accountUploadSlice.actions

export default accountUploadSlice.reducer
