import { useCallback, useContext, useState } from "react";
import Callout from "plaid-threads/Callout";
import Button from "plaid-threads/Button";
import InlineLink from "plaid-threads/InlineLink";
import { useSelector } from "react-redux";

import Link from "../Link";
import Context from "../../Context";
import Loader from "../../animation";
import { RootState } from "../../store";

import styles from "./index.module.scss";
import { formValidation } from "../../constants/formValidation";

const Header = () => {
  const { linkToken, backend, linkTokenError } = useContext(Context);

  // ==== REDUX STATE ====
  const mountedAccounts = useSelector(
    (state: RootState) => state.accountUpload.accountUploaded
  );

  const alreadyExisted = useSelector(
    (state: RootState) => state.accountUpload.alreadyExisted
  );

  const mountLoading = useSelector(
    (state: RootState) => state.accountUpload.uploadLoading
  );


  // ==== LOCAL STATE ====

  return (
    <div className={styles.grid}>
      <h3 className={styles.title}>Bank Integration with Plaid</h3>

      {/* {!linkSuccess ? ( */}
      <>
        <p className={styles.introPar}>
          Connect all bank acccounts associated with your business.
        </p>
        {/* message if backend is not running and there is no link token */}

        {/* Loading animation while report is running in the background */}
        {mountLoading ||
        mountedAccounts.length > 0 ||
        alreadyExisted.length > 0 ? (
          <div className={styles.uploaded_acc_container}>
            <header className={styles.uploaded_header}>
              {mountLoading ? (
                <div className={styles.loadingContainer}>
                  <span>Loading</span>
                  <span className={styles.dot}>.</span>
                  <span className={styles.dot}>.</span>
                  <span className={styles.dot}>.</span>
                </div>
              ) : (
                "Upload successful! Upload additional accounts"
              )}
              <span className={styles.uploaded_partition} />
            </header>

            {/* {mountLoading ? (
              <Loader />
            ) : (
              <>
                {mountedAccounts.map((account, i) => (
                  <div key={i} className={styles.uploaded_account}>
                    {account}
                  </div>
                ))}

                <header className={styles.uploaded_header}>
                  Existing Accounts
                  <span className={styles.uploaded_partition} />
                </header>
                {alreadyExisted.map((existingAccount, i) => (
                  <div key={i} className={styles.existing_account}>
                    {existingAccount}
                  </div>
                ))}
              </>
            )} */}
          </div>
        ) : (
          ""
        )}

        {!backend ? (
          <Callout warning>
            Unable to fetch link_token: please make sure your backend server is
            running and that your .env file has been configured with your
            <code>PLAID_CLIENT_ID</code> and <code>PLAID_SECRET</code>.
          </Callout>
        ) : /* message if backend is running and there is no link token */
        linkToken == null && backend ? (
          <Callout warning>
            <div>
              Unable to fetch link_token: please make sure your backend server
              is running and that your .env file has been configured correctly.
            </div>
            <div>
              If you are on a Windows machine, please ensure that you have
              cloned the repo with{" "}
              <InlineLink
                href="https://github.com/plaid/quickstart#special-instructions-for-windows"
                target="_blank"
              >
                symlinks turned on.
              </InlineLink>{" "}
              You can also try checking your{" "}
              <InlineLink
                href="https://dashboard.plaid.com/activity/logs"
                target="_blank"
              >
                activity log
              </InlineLink>{" "}
              on your Plaid dashboard.
            </div>
            <div>
              Error Code: <code>{linkTokenError.error_code}</code>
            </div>
            <div>
              Error Type: <code>{linkTokenError.error_type}</code>{" "}
            </div>
            <div>Error Message: {linkTokenError.error_message}</div>
          </Callout>
        ) : linkToken === "" ? (
          <div className={styles.linkButton}>
            <Button large disabled>
              Loading...
            </Button>
          </div>
        ) : (
          <div className={styles.linkButton}>
            <Link />
          </div>
        )}
      </>
    </div>
  );
};

Header.displayName = "Header";

export default Header;
