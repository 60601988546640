import React, { useEffect, useContext, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import Button from 'plaid-threads/Button';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import axios, { AxiosResponse } from 'axios';

import Context from '../../Context';
import {
  mountAccounts,
  uploadIsLoading,
  mountExistingAccounts,
  clearMountedAccounts,
  clearAlreadyExisting,
  setBusinessName,
  setBusinessEmail,
} from '../../store/accountUpload/accountUpload-slice';
import { formValidation } from '../../constants/formValidation';
import { AppDispatch } from '../../store';

import styles from '../Headers/index.module.scss';

// ========= TOAST MESSAGE CONFIG =============
const errorNotify = (msg: string) => toast.error(msg);
const successNotify = (msg: string) => toast.success(msg);

// function to get current param from URL
function getQueryStringValue() {
  const queryParams = new URLSearchParams(window.location.search);
  const firstEntry = queryParams.entries().next();

  // Check if the query parameter exists in the URL
  if (!firstEntry.done && firstEntry.value[0]) {
    // Replace the session storage with the new URL param
    sessionStorage.setItem('savedQueryParam', firstEntry.value[0]);
    return firstEntry.value[0];
  } else {
    // If no param in URL, retrieve from session storage
    const savedParam = sessionStorage.getItem('savedQueryParam');
    return savedParam ? savedParam : null;
  }
}

// =========== COMPONENT ===========
const Link = () => {
  const reduxDispatch = useDispatch<AppDispatch>();

  const { linkToken, dispatch } = useContext(Context);

  // ==== LOCAL STATE ====
  const [formFinish, setFormFinish] = useState(false);

  const onSuccess = React.useCallback(
    (public_token: string) => {
      // send public_token to server

      const param = getQueryStringValue();

      const setToken = async () => {
        /**
         * This redux action dispatch here is to...
         * -> render the loading component
         * -> remove the previous accounts that was uploaded by merchant in both newly mounted and already existing mounted merchant accounts, we are doing that because we are displaying uploaded merchant account based on the API response
         */
        reduxDispatch(uploadIsLoading(true));
        reduxDispatch(clearMountedAccounts());
        reduxDispatch(clearAlreadyExisting());

        // retrieving business name and email from local storage for the url we will be using for the API request
        const business_name = localStorage.getItem('business_name');
        const business_email = localStorage.getItem('email');

        // API url request config
        const url =
          '/api/set_access_token/' + business_name + '/' + business_email + '/' + param;

        await axios
          .post(url, `public_token=${public_token}`, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
          })
          // ======= SUCCESS ========
          .then((res: AxiosResponse) => {
            console.log(`ERROR CODE: ${res.data['error_code']}`, res);

            // if it is an error and the status is 200...
            if (res.data['error_message']) {
              dispatch({
                type: 'SET_STATE',
                state: {
                  itemId: `no item_id retrieved`,
                  accessToken: `no access_token retrieved`,
                  isItemAccess: false,
                },
              });

              // * this is the part where Mike want to change it success notify but the backend should have the response in a 200 and not error code
              successNotify(
                `${res.data['error_code']}: ${res.data['error_message']}`
              );
            } else {
              // for actual successful API request, we want delegate already existing and newly added account to their respected area...
              // if (res.data.already_exists.length > 0) {
              //   res.data.already_exists.map((account: string) => {
              //     reduxDispatch(mountExistingAccounts(account));

              //     errorNotify(`${account} already exist`);
              //   });
              // }

              // if (res.data.newly_added.length > 0) {
              //   res.data.newly_added.map((account: string) => {
              //     reduxDispatch(mountAccounts(account));

              //     successNotify(
              //       `Connection for ${account} was successful. Thank you!`
              //     );
              //   });
              // }

              dispatch({
                type: 'SET_STATE',
                state: {
                  itemId: res.data.item_id,
                  accessToken: res.data.access_token,
                  isItemAccess: true,
                },
              });
            }

            // unmount loading
            reduxDispatch(uploadIsLoading(false));
          })
          // ========= ERROR ==========
          .catch((err) => {
            console.log('err from axios request: ', err);

            dispatch({
              type: 'SET_STATE',
              state: {
                itemId: `no item_id retrieved`,
                accessToken: `no access_token retrieved`,
                isItemAccess: false,
              },
            });

            errorNotify(`${err.data['error_message']}`);

            // unmount loading
            reduxDispatch(uploadIsLoading(false));
          });
      };
      setToken();
      dispatch({ type: 'SET_STATE', state: { linkSuccess: true } });
      window.history.pushState('', '', '/');
    },
    [dispatch, reduxDispatch]
  );

  let isOauth = false;
  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken!,
    onSuccess,
  };

  if (window.location.href.includes('?oauth_state_id=')) {
    // TODO: figure out how to delete this ts-ignore
    // @ts-ignore
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (isOauth && ready) {
      open();
    }
  }, [ready, open, isOauth]);

  return (
    <>
      {formFinish ? (
        <Button
          type="button"
          large
          onClick={() => {
            open();
          }}
          disabled={!ready}
        >
          Connect Account
        </Button>
      ) : (
        <div>
          <Formik
            initialValues={{ businessName: '', email: '' }}
            validationSchema={formValidation}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);

              reduxDispatch(setBusinessName(values.businessName));
              reduxDispatch(setBusinessEmail(values.email));

              localStorage.setItem('business_name', values.businessName);
              localStorage.setItem('email', values.email);

              setFormFinish(true);
              actions.setSubmitting(false);
            }}
          >
            {({ errors, touched }) => (
              <Form className={styles.form_container}>
                <p className={styles.form_header}>
                  Please fill in your information below:
                </p>
                {/* ==== BUSINESS NAME ==== */}
                <div className={styles.form_field}>
                  <label htmlFor="businessName">Business Name: </label>
                  <section className={styles.field_validation_section}>
                    <Field
                      type="text"
                      id="businessName"
                      name="businessName"
                      className={
                        errors.businessName && touched.businessName
                          ? styles.invalid_field
                          : styles.input_field
                      }
                    />
                    {errors.businessName && touched.businessName && (
                      <span className={styles.field_error}>
                        {errors.businessName}
                      </span>
                    )}
                  </section>
                </div>

                {/* ==== EMAIL ==== */}
                <div className={styles.form_field}>
                  <label htmlFor="email">Email: </label>
                  <section className={styles.field_validation_section}>
                    <Field
                      type="text"
                      id="email"
                      name="email"
                      className={
                        errors.email && touched.email
                          ? styles.invalid_field
                          : styles.input_field
                      }
                    />
                    {errors.email && touched.email && (
                      <span className={styles.field_error}>{errors.email}</span>
                    )}
                  </section>
                </div>

                {/* ==== SUBMIT BUTTON ==== */}
                <button type="submit" className={styles.submit_button}>
                  <span>Submit</span>
                </button>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

Link.displayName = 'Link';

export default Link;
